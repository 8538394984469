import React from 'react';
import '../App.css'
import Navbar from "../components/Navbar";
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection'

function Home () {
    return (
        <>
            <Navbar />
            <HeroSection />
            <Footer />
        </>
    )
}

export default Home;