import React from 'react';
import './TipJar.css';

function TipJar() {
    return (
        <div className='tipjar-container'>
            <div className= 'jar'>
                <div className='tip-title'> My Tip Jar </div>
                <div className='tip-qr'></div>
                <div className='tip-link'> dogester.com/user </div>
            </div>


        </div>
    )
}

export default TipJar