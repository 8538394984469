import React from 'react';
import Navbar from "../components/Navbar";

const About = () => {
    const containerStyle = {
        textAlign: 'center',
        marginTop: '40px',
    };

    const imageStyle = {
        width: '200px', 
        height: '200px', 
        borderRadius: '50%',
        objectFit: 'cover'
    };

    return (
        <>
        <Navbar />
        <div style={containerStyle}>
            
        
            <img 
                src="../../images/youngyo.jpeg" 
                alt="Youngyo Na" 
                style={imageStyle}
            />
            <br></br>
            <br></br>
            <h1>About the Developer</h1>
            <h3>
                👋 Hi there! My name is Youngyo Na and I'm currently a software engineer on BlackRock's Digital Asset team. 
                <br></br>
                I work on integrating crypto trading/reconciliation within our SAAS platform and creating blockchain improvements to our tradfi ecosystem.
                <br></br>
                Outside of work, I enjoy playing Team Fortress 2 (<i>still!</i>) and flying general aviation aircraft.
            </h3>

            <br></br>
            
            <h1>How to Contact</h1>
            <h3>
                For any questions, comments, suggestions, please reach out at: {' '}
                    <a href="mailto:shiba@dogester.com" style={{ textDecoration: 'underline' }}>
                        shiba@dogester.com
                    </a>
            </h3>
        </div>
        </>
    );
};

export default About;