import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavButton } from './NavButton';
import './DashboardNavbar.css';

function DashboardNavbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
        setButton(false)
    }
    else {
        setButton(true)
    }
  };

  useEffect(() => {
    showButton()
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
        <nav className='navbar2'>
            <div className='navbar-container2'>
                <Link to='/dashboard' className='navbar-logo2' onClick={closeMobileMenu}>
                    Dogester <i className='fa-brands fa-bitcoin' />
                </Link>
                <div className='menu-icon2' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu2 active' : 'nav-menu2'}>
                    <li className='nav-item2'>
                        <Link to='/dashboard' className='nav-links2' onClick={closeMobileMenu}>
                            Dashboard
                        </Link>
                    </li>
                    <li className='nav-item2'>
                        <Link to='/send-tip' className='nav-links2' onClick={closeMobileMenu}>
                            Send Tip
                        </Link>
                    </li>
                    <li className='nav-item2'>
                        <Link to='/wallet' className='nav-links2' onClick={closeMobileMenu}>
                            Wallet
                        </Link>
                    </li>
                    <li className='nav-item2'>
                        <Link to='/activity' className='nav-links2' onClick={closeMobileMenu}>
                            Activity
                        </Link>
                    </li>
                    <li className='nav-item2'>
                        <Link to='/' className='nav-links-mobile2' onClick={closeMobileMenu}>
                            Log Out
                        </Link>
                    </li>
                </ul>
                <div className='menu-auth2'>
                    {button && <Link to='/'> <NavButton buttonStyle='btn--outline'>LOG OUT</NavButton></Link>}
                </div>
            </div>
        </nav>
    </>
  )
}

export default DashboardNavbar