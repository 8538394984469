import React from 'react';
import './ActionBox.css';
import { Link } from 'react-router-dom';

function AddFundBox() {
    return (
        <Link to='/deposit' className='link'>
        <div className='action-container'>
            <div className='action'> <i className='fa-solid fa-circle-plus' /> &nbsp; Add Funds </div>
        </div>
        </Link>
    )
}

export default AddFundBox