import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import BalanceBox from '../components/BalanceBox'
import CashOutBox from '../components/CashOutBox'
import AddFundBox from '../components/AddFundBox'
import TipJar from '../components/TipJar'
import Spacer from '../components/Spacer'

function Dashboard() {
  return (
    <>
      <DashboardNavbar />
      <Spacer />
      <BalanceBox />
      <Spacer />
      <AddFundBox />
      <Spacer />
      <CashOutBox />
      <Spacer />
      <TipJar />
      <Spacer />
    </>
  )
}

export default Dashboard