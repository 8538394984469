import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavButton } from './NavButton';
import './Navbar.css';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <= 960) {
        setButton(false)
    }
    else {
        setButton(true)
    }
  };

  useEffect(() => {
    showButton()
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    Dogester
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/terminal' className='nav-links' onClick={closeMobileMenu}>
                            Terminal
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/docs' className='nav-links' onClick={closeMobileMenu}>
                            Docs
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
                            About
                        </Link>
                    </li>
                </ul>
                <div className='menu-auth'>
                    {button && <Link to='/login'> <NavButton buttonStyle='btn--outline'>LOG IN</NavButton></Link>}
                    {button && <Link to='/signup'> <NavButton buttonStyle='btn--outline'>SIGN UP</NavButton></Link>}
                </div>
            </div>
        </nav>
    </>
  )
}

export default Navbar