import React from 'react'
import SignupBox from '../components/SignupBox'

function Signup() {
  return (
    <>
        <SignupBox />
    </>
  )
}

export default Signup