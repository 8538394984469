import React, { useState } from 'react';
import './SqlTerminal.css'; // Assuming you're using a separate CSS file

const SqlTerminal = () => {
  const [query, setQuery] = useState('');

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const executeQuery = () => {
    alert('Sorry, still in development.');
  };

  return (
    <div className="sql-terminal-container">
      <div className="sql-terminal">
        <textarea
          value={query}
          onChange={handleQueryChange}
          placeholder="Type your SQL query here..."
        />
        <button onClick={executeQuery}>Execute</button>
      </div>
    </div>
  );
};

export default SqlTerminal;