import React from 'react'
import LoginBox from '../components/LoginBox'

function Login() {
  return (
    <>
        <LoginBox />
    </>
  )
}

export default Login