import React from 'react';
import { useRef, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { AuthButton } from './AuthButton';
import './SignupBox.css';

const USER_REGEX = /^[a-zA-Z0-9-_]{4,24}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

function SignupBox() {

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const handleLoginClick = () => {
        alert("Sorry, this site is still under development.");
    };

    return (
        <div className='auth'>
            <div className='auth-container'>
                <Link to='/' className='logo'>
                    Dogester
                </Link>
                <div className='input-areas'>
                    <form>
                        <input type='username' name='username' placeholder='Username' className='auth-input' />
                        <input type='email' name='email' placeholder='Email' className='auth-input' />
                        <input type='password' name='password' placeholder='Password' className='auth-input' />
                        <input type='password' name='password' placeholder='Re-enter Password' className='auth-input' />
                    </form>
                    {/* <Link to='/reset-help' style={{ textDecoration: 'none' }}> <h3>Forgot password?</h3></Link> */}
                </div>

                <div className='whitespace'></div>

                {/* <Link to='/dashboard'> */}
                    <AuthButton className='authbtns' buttonStyle={'authbtn--outline'}
                        buttonSize='authbtn--large' onClick={handleLoginClick}>SIGN UP
                    </AuthButton>
                {/* </Link> */}

                <div className='whitespace2'></div>

                <Link to='/login'>Already have an account?  Log in instead.</Link>
            </div>
        </div>

    )
}

export default SignupBox