import React from 'react';
import { Link } from 'react-router-dom';
import { AuthButton } from './AuthButton';
import './LoginBox.css';

function LoginBox() {
    const handleLoginClick = () => {
        alert("Sorry, this site is still under development.");
    };
    
    return (
        <div className='auth'>
            <div className='auth-container'>
                <Link to='/' className='logo'>
                    Dogester
                </Link>
                <div className='input-areas'>
                    <form>
                        <input type='username' name='username' placeholder='Username' className='auth-input' />
                        <input type='password' name='password' placeholder='Password' className='auth-input' />
                    </form>
                    <Link to='/reset-help' style={{ textDecoration: 'none' }}> <h3>Forgot password?</h3></Link>
                </div>

                <div className='whitespace'></div>
                
                {/* <Link to='/dashboard'> */}
                    <AuthButton className='authbtns' buttonStyle={'authbtn--outline'}
                    buttonSize='authbtn--large' onClick={handleLoginClick}>LOG IN
                    </AuthButton>
                {/* </Link> */}

                <div className='whitespace3'></div>

                <Link to='/signup'>Don't have an account?  Sign up here.</Link>
            </div>
        </div>
       
    )
}

export default LoginBox