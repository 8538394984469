import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import Navbar from "../components/Navbar";
import spec from '../api/openapi.json';

const ApiDocs = () => {
    return (
        <>
        <Navbar />

        <SwaggerUI spec={spec} />
        </>
    )
    
};

export default ApiDocs;