import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./pages/Layout";
import Home from './pages/Home';
import About from './pages/About';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import ApiDocs from './pages/ApiDoc';
import SqlApp from './pages/SqlApp';

function App() {
  return (
    <>
      <div className="App"> 
        <BrowserRouter> 
          <Routes>
            <Route path='/'  element={<Layout />} >
              <Route index element={<Home />} />
              <Route path='login' element={<Login />} />
              <Route path='about' element={<About />} />
              <Route path='signup' element={<Signup />} />
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='docs' element={<ApiDocs />} />
              <Route path='terminal' element={<SqlApp />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
