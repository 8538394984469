import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import '../App.css';
import './HeroSection.css';


function HeroSection() {
  return (
    <div className='hero-container'>
        <h1>WOW! MUCH INFO!</h1>
        <p>Daily prices?  Historical Data?  Memes?  We have it all.</p>
        <p>(Not really tho, site is still under development)</p>
        <div className='hero-btns'>
            <Link to='/sign-up'>
            <Button className='btns' buttonStyle={'btn--outline'}
            buttonSize='btn--large'>GET STARTED</Button>
            </Link>
            <Link to='/sign-up'>
            <Button className='btns' buttonStyle={'btn--primary'}
            buttonSize='btn--large'>WATCH TRAILER
            <i className='far fa-play-circle' />
            </Button>
            </Link>
        </div>

    </div>
  )
}

export default HeroSection