import React from 'react';
import { Link } from 'react-router-dom';
import './AuthButton.css';

const STYLES = ['authbtn--primary', 'authbtn--outline', 'authbtn--test'];

const SIZES = ['authbtn--medium', 'authbtn--large'];

export const AuthButton = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
      <button
        className={`authbtn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
      >
        {children}
      </button>
  );
};