import React from 'react';
import { AuthButton } from './AuthButton';
import { Link } from 'react-router-dom';
import './BalanceBox.css';

function BalanceBox() {
    return (
        <div className='balance-container'>
            <div id = 'info'>
                <div className='balance-title'> Dogester Balance </div>
                <div className='balance'> 0 DOGE </div>
                <div className='send-btn'>
                    <Link to='/send-tip'>
                        <AuthButton className='authbtns' buttonStyle={'authbtn--outline'}
                            buttonSize='authbtn--large'>Send Tip
                        </AuthButton>
                    </Link>
                </div>

            </div>


        </div>
    )
    
}

export default BalanceBox