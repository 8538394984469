import React from 'react';
import { Link } from 'react-router-dom';
import './ActionBox.css';

function CashOutBox() {
    return (
        <Link to='/deposit' className='link'>
        <div className='action-container'>
            <div className='action'> <i className='fa-solid fa-circle-minus' /> &nbsp; Cash Out </div>
        </div>
        </Link>
    )
}

export default CashOutBox