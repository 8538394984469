import React from 'react'
import './Footer.css';
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer-container'>
        <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
                Join the #1 crypto community.  Start supercharging your trading today.
            </p>
        </section>
        <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/sign-up'>How it works</Link>
            <Link to='/'>Mission Statement</Link>
            <Link to='/'>Privacy Policy</Link>
            <Link to='/'>Terms of Service</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/about'>Contact</Link>
            <Link to='/'>Roadmap</Link>
            <Link to='/about'>Suggestion Box</Link>
            <Link to='/'>Donate</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Developer</h2>
            <Link to='/'>Get Started</Link>
            <Link to='/'>Documentation</Link>
            <Link to='/'>Tutorials</Link>
            <Link to='/'>Learn Center</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Newsletter</Link>
            <a href="https://discord.gg/EkkSsGmxpj" target="_blank" rel="noopener noreferrer">Discord</a>
            <Link to='/'>Youtube</Link>
            <Link to='/'>Twitter</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              Dogester
            </Link>
          </div>
          <small class='website-rights'>Dogester LLC 2024</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-mailchimp' />
            </Link>
            <a
              className='social-icon-link discord'
              href='https://discord.gg/EkkSsGmxpj'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Discord'
            >
              <i className='fab fa-discord' />
            </a>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Footer