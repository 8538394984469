import React from 'react';
import './Spacer.css';

function Spacer() {
    return (
        <div className='spacer'>
            
        </div>
    )
}

export default Spacer