import React from 'react';
import SqlTerminal from '../components/SqlTerminal';
import Navbar from '../components/Navbar';

function SqlApp() {
  return (
    <div className="SqlApp">
        <Navbar />
        <SqlTerminal />
    </div>
  );
}

export default SqlApp;